<template>
  <v-card class="text-center pa-4"  :min-height="$vuetify.breakpoint.smAndUp ? '350':'250'">
    <template>
        <v-img class="mx-auto mb-2" style="border-top-left-radius:initial" src="../static/terminal_sin_logo.png" :width="$vuetify.breakpoint.smAndUp ?'120px':'95px'"></v-img>
        <span v-if="!$vuetify.breakpoint.smAndUp" class="subtitle-1 d-block primary--text text--darken-1 mt-4 font-weight-bold">Tu transacción fue enviada a la terminal</span>
        <h3 v-else class="d-block primary--text text--darken-1 mt-4 font-weight-bold">Tu transacción fue enviada a la terminal</h3>
        <!-- TITULOS -->
      <template v-if="!$vuetify.breakpoint.smAndUp">
        <span v-if="diferenciaComponente < 4 && mensaje === 'Esperando que se desocupe el pico'" class="subtitle-1 d-block mt-4 mb-2">Su despacho está por comenzar</span>
        <span v-else class="subtitle-1 d-block mt-4 mb-2">{{mensaje}}</span>
      </template>
      <template v-else>
        <h3 v-if="diferenciaComponente < 4 && mensaje === 'Esperando que se desocupe el pico'" class="d-block mt-4 mb-2">Su despacho está por comenzar</h3>
        <h3 v-else class="d-block mt-4 mb-2">{{mensaje}}</h3>
      </template>
        <!-- SUBTITULO -->
        <span v-show="mensaje === 'Despachando'" class="contador" :style="$vuetify.breakpoint.smAndUp?'font-size: 20px;':'font-size: 14px;'">Transacción en curso</span>
        <span v-if="mensaje === 'Transacción terminada'">
          <font-awesome-icon style="  --fa-primary-color: #50A88E; --fa-secondary-color: #64D2B24D" icon="fa-duotone fa-circle-check" class="fa-3x"/>
        </span>
        <!-- CONTADOR -->
        <span v-show="loader === false">
          <v-progress-linear
            class="ma-auto"
            style="width:5vw"
            color="knowledge lighten-2"
            buffer-value="0"
            stream
          ></v-progress-linear>
        </span>
        <span v-if="diferenciaComponente > 0 && esperando" class="contador animacion">{{diferenciaComponente}} Seg</span>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'ModalDespachando',
  props: {
    mensaje: {
      default: '',
      type: String,
    },
    esperando: {
      default: false,
      type: Boolean,
    },
    diferencia: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      loader: false,
      diferenciaComponente: 0,
    };
  },
  mounted() {
    setInterval(this.updateCounter, 1000);
  },
  // computed: {
  //   variableDiferencia() {
  //     if (this.diferencia > 0) this.restar();
  //     return this.diferencia;
  //   },
  // },
  methods: {
    async updateCounter() {
      if (this.diferenciaComponente === 0 && this.esperando === true) this.diferenciaComponente = this.diferencia;
      if (this.diferenciaComponente > 0) this.diferenciaComponente -= 1;
      this.loader = true;
    },
  },
};
</script>

<style lang='scss'>
.contador{
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.animation{
  animation-iteration-count: 1;
  animation-duration: 2;
  animation-name: aparecer;
}
@keyframes aparecer {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
</style>
